import React, { memo } from 'react'
interface IProps {
    showError: Boolean,
    errorMessage: string
}
const LoginFail: React.FC<IProps> = ({ showError, errorMessage }: IProps) => {
    return (
        <div id="alert" className={`alert alert-danger ${showError ? '' : 'invisible'}`} role="alert">{errorMessage}</div>
    )
}
export default memo(LoginFail)
import React from 'react';
import { Helmet } from "react-helmet";
import ErrorDisplay from './ErrorDisplay'
import axios from 'axios'
import ErrorMessage, { errorMessageMap } from './ErrorMessage'
interface IState {
    errorMessage: string,
    showError: boolean
}
class App extends React.Component<any, IState> {
    render = () => {
        return <>
            <Helmet>
                <title>登录平台</title>
            </Helmet>
            <form id="form" className="form-signin">
                <ErrorDisplay showError={this.state.showError} errorMessage={this.state.errorMessage} />
                <h4 className="h4 mb-3 font-weight-normal flex align-center">
                    <img src="/favicon.png" alt="" className="favicon" />
                    欢迎登录温度实时监控 <br />
                    自动化采集系统
                </h4>
                <input type="text" id="user-name" className="form-control no-border name-input-border" onChange={this.clearError.bind(this)} placeholder="用户名" required autoFocus autoComplete="off" />
                <br />
                <input type="password" id="password" placeholder="密码" className="form-control no-border password-input-border" />
                <br />
                <button id="submit" className="btn btn-primary btn-block" type="submit" onClick={this.attemptLogin}>登录</button>
                <p className="mt-5 mb-3 text-muted">&copy; 上勘院量测中心研发所版权所有 2018~2019</p>
            </form>
        </>
    }
    state = {
        errorMessage: '',
        showError: false //默认没有登陆失败哦
    }
    clearError() {
        this.setState({ showError: false })
    }
    preCheck({ userName, password }) {
        if (userName && password) return true
        this.setState({ showError: true, errorMessage: errorMessageMap[ErrorMessage.FormNotComplete] })
        return false
    }
    attemptLogin = async (e: React.MouseEvent) => {
        e.preventDefault()
        let userName = (document.getElementById('user-name') as HTMLInputElement).value
        let password = (document.getElementById('password') as HTMLInputElement).value
        if (!this.preCheck({ userName, password })) return
        try {
            await axios.post('/login', {
                userName,
                password
            })
            window.location.href = '/'
        } catch (error) {
            this.setState({ showError: true, errorMessage: errorMessageMap[ErrorMessage.LoginFail] })
        }
    }
}
export default App;

